<template>
  <ByBrandReport />
</template>

<script>
import ByBrandReport from '@/components/pages/product/ByBrandReport'

export default {
  components: {
    ByBrandReport
  }
}
</script>
